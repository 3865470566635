import React from 'react';
import './home.css';

export default function Home() {
  return (
    <div className='Home'>
      <h1> Hola!, Site Under construction </h1>
      <p> We'll be back live soon! </p>
    </div>
  )
}
