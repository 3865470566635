import React, { useState } from 'react'
import './login.css';
import { useNavigate } from 'react-router-dom';


export default function Login() {

  const navigate = useNavigate();


  const [details, setDetails] = useState({
    username: '',
    password: '',
  });

  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false)


  const handleChange = (target) => {
    const name = target.name;
    const value = target.value;

    if(isError) setIsError(false);
    
    setDetails(prev => ({...prev, [name]: value}));
  }



  const handleSubmit = () => {
    if(details.username !== 'bencarthytravels' || details.password !== '246850') {
      setIsError(true);
      return;
    }

    setLoading(true);
    sessionStorage.setItem('username', details.username);
    sessionStorage.setItem('password', details.password);

    setTimeout(() => {
      setLoading(false);
      setDetails({username: '', password: ''});
      navigate('/all-blogs')
    }, 3000)
  }





  return (
    <div className='login'>
      <h2 className='title'> Login </h2>
      <p className='error-message'> {isError && 'Wrong username or password'} </p>


      <input 
        type="text" 
        name="username" 
        placeholder='username' 
        value={details.username}
        onChange={(e) => handleChange(e.target)}
        />

      <input 
        type="password" 
        name="password" 
        placeholder='password'
        value={details.password} 
        onChange={(e) => handleChange(e.target)}
      />

      <button onClick={handleSubmit}> {loading ? 'loading...' : 'login'} </button>
    </div>
  )
}
